import React from "react"
import { Link, graphql } from "gatsby"
import he from "he"

import Layout from "../components/Layout"
import HeadMeta from "../components/HeadMeta"
import PageHeader from "../components/PageHeader"
import Breadcrumb from "../components/Breadcrumb"
import LanguageShowcase from "../components/LanguageShowcase"
import BrowseList from "../components/BrowseList"
import ExternalFile from "../components/ExternalFile"

export default props => {
  const { data } = props
  const fields = data?.languagesJson?.fields || {}

  const translationsList = (data.allTranslationsJson.edges || []).sort(
    (a, b) => {
      if (!a.node.phrase || !b.node.phrase) {
        return 0;
      }
      
      if (a.node.phrase.fields.name < b.node.phrase.fields.name) {
        return -1
        
      }
      if (a.node.phrase.fields.name > b.node.phrase.fields.name) {
        return 1
      }
      return 0
    }
  )

  return (
    <Layout>
      <HeadMeta pageTitle={`Language: ${fields.name}`} />
      <PageHeader>Language: {fields.name}</PageHeader>
      <Breadcrumb
        route={[
          { name: "Multilingual library signage", href: "/" },
          { name: "Languages", href: "/language/" },
          { name: fields.name, href: `/${fields.slug}` },
        ]}
      />

      {(fields?.nameTranslated || '').length > 0 && (
        <LanguageShowcase
          languageCode={fields.isoCode}
          languageName={fields.name}
          direction={fields.direction}
          fontFile={
            (fields?.font || '').length > 0
              ? `fonts/${fields.name}/${fields.font}.ttf`
              : null
          }
          encoding={fields.encoding}
        >
          {he.decode(fields.nameTranslated)}
        </LanguageShowcase>
      )}

      <p>
        {fields.zip && (
          <>
            <ExternalFile
              filename={fields.zip}
              type="font"
              title="Download font"
            />
            <Link to="/installing-fonts">How to install this font</Link>
          </>
        )}
      </p>
      <h2>Available phrases</h2>
      <BrowseList
        items={translationsList.map(translationEdge => {
          return {
            fields: {
              slug: translationEdge.node.fields.slug,
              name: translationEdge.node.phrase?.fields.name,
            },
          }
        }).filter((item) =>
          (item?.fields?.slug || '').length > 0 &&
          (item?.fields?.name || '').length > 0
        )}
        sortAlpha={true}
      />
      <p>
        <Link to={`tableview/`}>
          View all translations as a table
        </Link>
      </p>
    </Layout>
  )
}

export const query = graphql`
  query LanguagePageQuery($languageID: Int) {
    languagesJson(fields: { id: { eq: $languageID }}) {
      fields {
        name
        nameTranslated
        isoCode
        encoding
        direction
        font
        zip
        slug
      }
    }

    allTranslationsJson(
      filter: { fields: { enabled: { eq: true }, languageID: { eq: $languageID } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          phrase {
            fields {
              name
            }
          }
        }
      }
    }
  }
`
