// extracted by mini-css-extract-plugin
export var baseline = "8px";
export var borderRadius = "2px";
export var borderRadiusLg = "4px";
export var borderRadiusMd = "2px";
export var browseFilter = "BrowseList-module--browseFilter--SsC0g";
export var browseList = "BrowseList-module--browseList--tMEbg";
export var buttonUnstyled = "BrowseList-module--buttonUnstyled--PwVJ4";
export var colourBlack = "#111";
export var colourGrey800 = "#333";
export var colourGrey900 = "#222";
export var colourHighlight = "#ed0180";
export var colourPrimary = "#f68b1f";
export var colourSecondary = "#64c3af";
export var colourWhite = "#fff";
export var fontFamilyPrimary = "\"Gotham A\",\"Gotham B\",Montserrat,Helvetica,Arial,sans-serif";
export var fontFamilySecondary = "\"Chronicle Display A\",\"Chronicle Display B\",\"Crimson Text\",\"Times New Roman\",Times,serif";
export var fontScale = "1.3";
export var formRow = "BrowseList-module--formRow--tA7JW";
export var listUnstyled = "BrowseList-module--listUnstyled--Ms2hk";
export var mediaLg = "60rem";
export var mediaMd = "40rem";
export var mediaSm = "33.5rem";
export var mediaXlg = "80rem";
export var mediaXs = "20rem";
export var mediaXxlg = "100rem";
export var screenreaderOnly = "BrowseList-module--screenreaderOnly--HfQ3O";
export var shadow = "BrowseList-module--shadow--oqGaf";
export var submitButton = "BrowseList-module--submitButton--0CRDc";
export var textFieldTitle = "BrowseList-module--textFieldTitle--iUTyT";
export var textSmoothing = "BrowseList-module--textSmoothing--tFo56";
export var textUppercase = "BrowseList-module--textUppercase--JQLlH";