// extracted by mini-css-extract-plugin
export var baseline = "8px";
export var borderRadius = "2px";
export var borderRadiusLg = "4px";
export var borderRadiusMd = "2px";
export var buttonUnstyled = "ExternalFile-module--buttonUnstyled--7+hT9";
export var colourBlack = "#111";
export var colourGrey800 = "#333";
export var colourGrey900 = "#222";
export var colourHighlight = "#ed0180";
export var colourPrimary = "#f68b1f";
export var colourSecondary = "#64c3af";
export var colourWhite = "#fff";
export var externalFile = "ExternalFile-module--externalFile--LLUur";
export var fontFamilyPrimary = "\"Gotham A\",\"Gotham B\",Montserrat,Helvetica,Arial,sans-serif";
export var fontFamilySecondary = "\"Chronicle Display A\",\"Chronicle Display B\",\"Crimson Text\",\"Times New Roman\",Times,serif";
export var fontScale = "1.3";
export var formRow = "ExternalFile-module--formRow--4Vor4";
export var icon = "ExternalFile-module--icon--EXinP";
export var listUnstyled = "ExternalFile-module--listUnstyled--+3Crz";
export var mediaLg = "60rem";
export var mediaMd = "40rem";
export var mediaSm = "33.5rem";
export var mediaXlg = "80rem";
export var mediaXs = "20rem";
export var mediaXxlg = "100rem";
export var screenreaderOnly = "ExternalFile-module--screenreaderOnly--KpiGh";
export var shadow = "ExternalFile-module--shadow--wp3Wt";
export var submitButton = "ExternalFile-module--submitButton--4S+6j";
export var textFieldTitle = "ExternalFile-module--textFieldTitle--wfVFG";
export var textSmoothing = "ExternalFile-module--textSmoothing--CbWgU";
export var textUppercase = "ExternalFile-module--textUppercase--TZlxj";