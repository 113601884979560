import React from "react"
import { StaticQuery, graphql } from "gatsby"

import SVGIcon from "../SVGIcon"

import * as css from "./ExternalFile.module.scss"

const ExternalFile = ({ filename, languageName, type, title }) => {
  let filePath =
    type === "font" ? ["fonts", filename] : [languageName, type, filename]

  const render = data => (
    <a
      className={[css.externalFile].join(" ")}
      href={[data.site.siteMetadata.baseAssetUrl.replace(/\/$/, "")]
        .concat(filePath)
        .join("/")}
      target="_blank"
      rel="noopener noreferrer"
    >
      {type === "image" && (
        <SVGIcon name="pictures" size="sm" className={css.icon} />
      )}
      {(type === "word" || type === "font") && (
        <SVGIcon name="download" size="sm" className={css.icon} />
      )}
      {title}
    </a>
  )

  return (
    <StaticQuery
      query={graphql`
        query ExternalFileQuery {
          site {
            siteMetadata {
              baseAssetUrl
            }
          }
        }
      `}
      render={render}
    />
  )
}

export default ExternalFile
